<template>
    <b-modal
      v-model="data.showModal"
      button-size="sm"
      modal-class="issue-modal"
      title-class="w-100"
      footer-class="w-100"
      no-fade
      @show="onShow"
      size="md"
      :no-close-on-backdrop="true"
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <h1 class="flex gap-2 items-center text-nowrap">
          <div class="text-xl font-bold">Issue</div>
          <div v-if="issue" class="text-xl"> - {{ issue.issue_description }}</div>
        </h1>
      </div>

      <!-- Modal content -->
      <div v-if="issue" class="flex flex-column gap-4">
        <table class="table-issue">
          <tbody>

            <tr>
              <td class="row-fit">Description</td>
              <td>
                <b-form-input
                  v-model="issue.issue_description"
                  autocomplete="off"
                  size="sm"
                  trim
                  autofocus
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td class="row-fit">Prefix</td>
              <td>
                <b-form-input
                  v-model="issue.issue_prefix"
                  autocomplete="off"
                  size="sm"
                  trim
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td class="row-fit">Suffix</td>
              <td>
                <b-form-input
                  v-model="issue.issue_suffix"
                  autocomplete="off"
                  size="sm"
                  trim
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td class="row-fit">Has Value</td>
              <td>
                <button-select
                  v-model="issue.issue_has_value"
                  :options="options.issue_has_value"
                  size="sm"
                  class="w-50"
                />
              </td>
            </tr>

            <tr v-if="issue.issue_has_value === 1">
              <td class="row-fit">Placeholder</td>
              <td>
                <b-form-input
                  v-model="issue.issue_placeholder"
                  autocomplete="off"
                  size="sm"
                  trim
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex flex-col gap-2">
          <h5>Periods</h5>
          <div>
            <div v-for="issuePeriod in issuePeriods" :key="`issuePeriod${issuePeriod.period_id}`">
              <b-form-checkbox
                v-model="issuePeriod.period_checked"
                :value="true"
                :unchecked-value="false"
                class="ml-2"
              >
                {{ issuePeriod.period_description }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-100 flex justify-between gap-4 items-center">
        <div>
          <b-btn
            variant="primary"
            size="sm"
            @click="saveIssue()"
          >
            Save
          </b-btn>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import {
  datepickerLocale,
  stringToNumber,
} from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'IssueModal',
  components: {
    ButtonSelect,
  },
  props: {
    data: Object,
    periods: Array,
  },
  computed: {
    changes() {
      return {
        issue_description: this.issue.issue_description,
        issue_prefix: this.issue.issue_prefix,
        issue_suffix: this.issue.issue_suffix,
        issue_placeholder: this.issue.issue_placeholder,
        issue_has_value: this.issue.issue_has_value,
        issue_periods: this.issuePeriods
          .filter((item) => item.period_checked)
          .map((item) => item.period_id),
      };
    },
    invoiceData() {
      const invoiceData = { ...this.invoice };
      invoiceData.price_brutto = stringToNumber(invoiceData.price_brutto);
      return invoiceData;
    },
    invoiceValidation() {
      console.log(this.invoiceData.price_brutto);
      return {
        invoice_code: this.invoiceData.invoice_code.length > 0,
        price_brutto: !isNaN(this.invoiceData.price_brutto) && this.invoiceData.price_brutto !== 0,
      };
    },
    datepickerLocale() {
      return datepickerLocale;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.invoice) return false;
      if (this.isLoading) return false;
      return Object.values(this.invoiceValidation).every((val) => val === true);
    },
  },
  data() {
    return {
      loadingCount: 0,
      issue: null,
      issuePeriods: [],
      options: {
        issue_has_value: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 },
        ],
      },
    };
  },
  methods: {
    onShow() {
      this.fetchIssue();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    buildIssuePeriods() {
      this.periods.forEach((period) => {
        this.issuePeriods.push({
          period_id: period.id,
          period_description: period.period_description,
          period_checked: this.issue.issue_periods.includes(period.id),
        });
      });
    },
    fetchIssue() {
      this.loadingCount++;
      this.$http
        .get(`/burn_sample/issue/${this.data.issue.id}`)
        .then((res) => {
          this.issue = res.body.issue;
          this.buildIssuePeriods();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch issue: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveIssue() {
      this.loadingCount++;
      this.$http
        .put(`/burn_sample/issue/${this.data.issue.id}`)
        .send({ changes: this.changes })
        .then(() => {
          this.$emit('change', this.data.issue.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update issue: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveInvoice() {
      this.loadingCount++;
      this.$http
        .put(`/invoice/${this.data.invoice.id}`)
        .send({ changes: this.invoiceData })
        .then(() => {
          this.$emit('change', this.data.invoice.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteInvoice() {
      if (!confirm(`Do you really wish to delete invoice '${this.data.invoice.invoice_code}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/invoice/${this.data.invoice.id}`)
        .then(() => {
          this.$emit('delete', this.data.invoice.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    uploadFile() {
      this.loadingCount++;
      const formData = new FormData();
      formData.append('upload', this.upload);
      formData.append('mtime', this.upload.lastModified / 1000);
      this.$http
        .post(`/invoice/${this.data.invoice.id}/files`)
        .send(formData)
        .then((res) => {
          this.invoice.files.push(res.body.invoiceFile);
          this.$emit('change', this.data.invoice.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.upload = null;
          this.loadingCount--;
        });
    },
    deleteFile(file) {
      if (!confirm(`Do you really wish to delete file '${file.filename}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/invoice/${this.data.invoice.id}/files/${file.id}`)
        .then(() => {
          this.invoice.files = this.invoice.files.filter((f) => f.id !== file.id);
          this.$emit('change', this.data.invoice.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-invoice {
    tr {
      border-top: 5px solid transparent;
    }

    tr > td:first-child {
      padding-right: 10px;
    }
  }
</style>
